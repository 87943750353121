@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display+SC:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

/* Custom CSS to hide the slider thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Chrome, Safari, newer Edge */
  appearance: none;
  width: 0px; /* Set width to 0 to effectively hide it */
  height: 0px; /* Set height to 0 */
}

input[type="range"]::-moz-range-thumb {
  -moz-appearance: none; /* Firefox */
  appearance: none;
  width: 0px;
  height: 0px;
}

input[type="range"]::-ms-thumb {
  -ms-appearance: none; /* IE */
  appearance: none;
  width: 0px;
  height: 0px;
}
